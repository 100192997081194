// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import Alpine from 'alpinejs';

/**
 * ------------------------------------------------------------------------
 * Main application
 * ------------------------------------------------------------------------
 */

window.addEventListener('load', () => {
  document.documentElement.classList.remove('no-js');
  window.Alpine = Alpine;
  Alpine.start();
});
